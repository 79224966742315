// Gatsby supports TypeScript natively!
import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

const faqs = [
  {
    question: "Where Do Your Offer Detailing Services?",
    answer:
      "We are 100% mobile, so, we can come to your home or office. We have self-contained units with power, water, and supplies. Book an appointment and our specialists will meet you at a time and location of your choice.",
  },
  {
    question: "How Long Does It Take to Confirm My Appointment?",
    answer:
      "We confirm all appointments within 24 hours of receiving the request through e-mail or phone.",
  },
  {
    question: "How Long Will It Take to Detail My Car?",
    answer:
      "The time needed to detail your car will depend on various factors. Generally, it will take about 3 hours to complete a full detailing. Exterior or interior detailing ‘only’ would require an average of 2 hours. SUVs and pickup trucks may require more time to complete.Once you complete our pre-cleaning checklist, we will give you a better timeframe of the required time.",
  },
  {
    question: "What Is the Best Time to Get My Car Detailed?",
    answer:
      "Winter is the most critical season to detail your car. This is due to changes in temperatures, salt, road construction, and other elements that are hard on your car. You need to know that the magnesium chloride used on the roads during winter is harmful to your car. That said generally, every time your car is dirty, oxidized, has some small scratches, you should have it detailed to restore it’s shine and freshness.",
  },
  {
    question: "Do You Offer Personalized Car Detailing Services?",
    answer:
      "To us, the customer is King. We are large enough to offer wholistic auto detailing services but small enough to offer personalized services. Just give us your budget, and we will back with a custom quote.",
  },
  {
    question: "After How Long Should My Car Be Detailed?",
    answer:
      "Detail your car at least once per year. Detailing keeps your car looking good and maintains its trade-in/resale value. If you want to sell or trade-in your car easily, detail it a week before. Note that if you take a long time without detailing, it will cost a lot of money when you eventually decide to do it. We advise our customer to take detailing as a routine maintenance program. We recommend at least two to 5 times of detailing per year. Your car washing and driving habits will determine the detailing. The storage of your car will also determine the level of the number of detailing it would require per year.",
  },
  {
    question: "What Causes Swirl Marks on My Car?",
    answer:
      "Swirl marks are fine and small scratches on the paint of your car. They are mostly caused by wiping your car with a dusty dry towel. Incidentally, improper washing as well as drying techniques can also cause swirls. Polishing the paint of your car is the best way to remove the swirl marks. Another option is applying products that would temporally mask or hide the swirl marks. However, we don’t recommend this option. The results are short-lived and minimal but polishing the car paint will remove all the visible swirls on your car. The car will retain a deep, wet look as well.",
  },
  {
    question:
      "If I Am Offered Clean Washes and Detailing, Should I Go for It? Or Is It a Trap?",
    answer:
      "It’s tempting to take cheap detailing and car wash offers but cheap is expensive. Our answer is simple. Say ‘No.’ It’s safer for you and your car. Improper wash will only damage your paint and you would end up spending more on paint correction.",
  },
  {
    question: "Does Wax Remove Scratches?",
    answer:
      "No. Wax does not remove scratches or swirls. It offers a protective barrier that protects your car from oxidation and other harmful elements in the environment.",
  },
  {
    question: "What Is Synthetic Sealant?",
    answer:
      "Synthetic sealant is a great alternative to wax. It’s made of polymer and it adheres to the paint on your car, protecting it from UV rays and hazardous element in the environment. In addition to its protective power, synthetic sealant gives your car a new-like shine and hydrophobic properties.",
  },
  {
    question:
      "What Is the Difference Between Car Wax and Sealant? Which Is Better?",
    answer:
      "Car wax and paint sealant perform the same purposes. They protect your car from the hot sun and other harmful elements in the air and give it a wet-look shine. The two products also protect synthetic car materials like acrylic resins or polymers. Either wax or sealant will work well on your car; none is better than the other.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <Layout>
      <Seo title="FAQ" description="We are 100% mobile, so, we can come to your home or office. We have self-contained units with power, water, and supplies. Book an appointment today." />
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
            <h1 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Frequently asked questions
            </h1>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-medium text-gray-900">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </Layout>
  );
}
